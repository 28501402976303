.forum-body-image{
 width: 100%;
}

.forum-new-post{
 align-items: center;
 background-color: #efeae4;
 border-radius: 30px;
 display: flex;
 gap: 20px;
 margin: 50px auto;
 padding: 30px;
 width: 60%;
}

.forum-new-post button{
 background: none;
 border: 0;
 margin: 0;
 padding: 0;
}

.forum-new-post p{
 color: #AD9779;
 font-weight: 400;
 font-size: 18px;
 margin-bottom: 8px;
}

.message-box {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 background-color: #efeae4;
 /* Adjust the color to match your theme */
 color: #333;
 /* Adjust text color as needed */
 text-align: center;
 padding: 10px;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
 z-index: 1000;
 /* Ensure it's above other elements */
}