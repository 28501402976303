.legal-guides-grid {
 display: grid;
 gap: 20px;
 margin: 100px auto;

 /* Default to 1 column on small screens */
 grid-template-columns: 1fr;

 /* Medium screens (e.g., tablets) */
 @media (min-width: 600px) {
  grid-template-columns: repeat(2, 1fr);
 }

 /* Large screens (e.g., desktops) */
 @media (min-width: 1024px) {
  grid-template-columns: repeat(3, 1fr);
 }
}