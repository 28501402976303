.active-nav-link {
 color: #AD9779;
 text-decoration: underline;
}

.navbar {
 align-items: center;
 display: flex;
 justify-content: space-between;
 padding: 16px 100px;
 flex-wrap: wrap;
 /* Allow items to wrap */
}

.navbars {
 display: flex;
 gap: 20px;
 flex-wrap: wrap;
 /* Allow navbar items to wrap */
}

.navbar-button {
 background-color: white;
 border: 0;
 color: #10172C;
 text-decoration: none;
}

.register-button {
 background-color: #AD9779;
 border: 0px;
 color: white;
 padding: 15px 40px;
 text-decoration: none
}

/* Media query for smaller screens */
@media (max-width: 768px) {

 /* Adjust the max-width as needed */
 .navbar {
  flex-direction: column;
  /* Stack the items vertically */
  align-items: center;
  /* Center align the items */
  padding: 20px;
  /* Adjust padding for smaller screens */
 }

 .navbars {
  justify-content: center;
  /* Center align the navbar buttons */
  margin-bottom: 20px;
  /* Add some margin at the bottom */
 }

 .register-button {
  margin-top: 20px;
  /* Add some margin at the top */
 }
}

.user-initials {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 60px;
 /* Diameter of the circle */
 height: 60px;
 /* Diameter of the circle */
 border-radius: 50%;
 /* Makes it circular */
 font-size: 24px;
 /* Adjust as needed */
 font-weight: bold;
 color: #fff;
 /* White text */
 background-color: #AD9779;
}