.about-image {
 margin: 80px 0;
 width: 100%;
}

.home-page-body {
 padding: 0 100px;
}

.legal-database-content {
 display: grid;
 grid-template-columns: repeat(3, 1fr);
 gap: 20px;
 padding: 50px 100px 200px 100px;
}

.legal-database-image {
 width: 50%;
}

.legal-database-image-container {
 text-align: center;
}

.main-body-image {
 width: 100%;
}

@media (max-width: 768px) {
 .legal-database-content {
  grid-template-columns: repeat(2, 1fr);
  padding: 0 20px;
 }
}

@media (max-width: 480px) {
 .legal-database-content {
  grid-template-columns: 1fr;
  padding: 0 10px;
 }
}