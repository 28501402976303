/* customAlert.css */
.custom-alert {
 background-color: #10172C;
 /* Match your theme color */
 color: white;
 padding: 20px;
 position: fixed;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 1000;
 border-radius: 5px;
 text-align: center;
 animation: fadeInOut 3s;
}

@keyframes fadeInOut {

 0%,
 100% {
  opacity: 0;
 }

 10%,
 90% {
  opacity: 1;
 }
}

/* Add this to your existing CSS file */
.loading-dots span {
 display: inline-block;
 width: 8px;
 height: 8px;
 border-radius: 50%;
 background-color: #AD9779;
 /* Match your theme color */
 margin: 0 3px;
 animation: loading 1.4s infinite ease-in-out both;
}

.loading-dots span:nth-child(1) {
 animation-delay: -0.32s;
}

.loading-dots span:nth-child(2) {
 animation-delay: -0.16s;
}

@keyframes loading {

 0%,
 80%,
 100% {
  transform: scale(0);
 }

 40% {
  transform: scale(1.0);
 }
}