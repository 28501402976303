* {
 margin: 0;
 box-sizing: border-box;
 /* Add this property to include padding and border in element's total width */
}

form input {
  /* height: 69px; Set a fixed height of 69px */
  width: 100%; /* Take the full width of the box */
  padding: 10px; /* Add padding for spacing inside the input if needed */
}

.body {
 background-color: #D8D8D8;
 display: flex;
 align-items: center;
 justify-content: center;
 /* height: 500px; */
}

.br{
 height: 10px;
}

.signin-button {
 background-color: white;
 border: 0;
 color: #AD9779;
}

.signup-button {
 margin: 20px 0;
 background-color: #333333;
 border: 0;
 color: white;
 padding: 10px;
 width: 100%;
}

.signup-page {
 background-color: white;
 flex: 1;
 /* Use flex to make it stretch the full length */
 max-width: 100%;
 /* Ensure it doesn't exceed the parent's width */
 margin: 60px 480px;
 /* Add margin to create equal horizontal margins */
 padding: 30px 50px;
 /* Add some padding to separate content from the edges */
}

.signup-page > div {
 display: flex;
 justify-content: center;
 margin: 20px 0;
}

.signup-page p {
 font-size: 24px;
 font-weight: 700;
 font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
 line-height: 72.66px;
 text-align: center;
}

.signup-page label {
 font-size: 12px;
 font-weight: 700;
 line-height: 20px;
}

.signup-page span {
 font-size: 12px;
 font-weight: 700;
 line-height: 20px;
}

.loader {
  border: 5px solid #f3f3f3;
  /* Light grey */
  border-top: 5px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Center the loader */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.snackbar {
  visibility: hidden;
  /* Hidden by default */
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  /* Black background color */
  color: #fff;
  /* White text color */
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

.show-snackbar {
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}