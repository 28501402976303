.forum-post{
 border: 1px solid grey;
 display: flex;
 flex-direction: column;
 gap: 15px;
 margin: 10px 100px;
 padding: 30px;
}

.forum-post h1{
 font-size: 4vh;
 font-style: italic;
 font-weight: 500;
}

.forum-post p{
 font-size: 3vh;
 font-weight: 400;
}