.research-result-body {
 background-color: #10172C;
 display: flex;
 flex-wrap: wrap;
 gap: 10px;
 justify-content: space-between;
 margin-bottom: 10px;
 padding: 20px 100px;
}

.research-result-button {
 align-items: center;
 background-color: #AD9779;
 border: 0;
 color: white;
 cursor: pointer;
 display: flex;
 flex: 1;
 justify-content: center;
 min-height: 40px;
 min-width: 120px;
 margin: 10px 0;
 max-height: 40px;
 max-width: 120px;
 text-decoration: none;
}

.resarch-result-item {
 color: white;
 flex: 3;
 font-size: 4vh;
 font-weight: bolder;
}