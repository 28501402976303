.header {
 background-color: #10172C;
 display: flex;
 justify-content: space-between;
 padding: 20px 100px;
 flex-wrap: wrap;
 /* Add this to allow wrapping */
}

.header-cards {
 display: flex;
 gap: 10px;
 flex-wrap: wrap;
 /* Add this to allow wrapping inside the header-cards div */
}

/* Media query for smaller screens */
@media (max-width: 600px) {

 /* Adjust the max-width as needed */
 .header {
  flex-direction: column;
  /* Stack the items vertically */
  align-items: center;
  /* Center align the items */
  padding: 20px;
  /* Adjust padding for smaller screens */
 }

 .header-cards {
  justify-content: center;
  /* Center align the header cards */
 }
}