*{
 margin: 0;
 padding: 0;
}

form .button {
 background-color: #AD9779;
 color: white;
 height: 70px;
 margin-top: 20px;
 width: 100%;
}

.about,
.quicklinks,
.get-in-touch,
.newsletter {
 flex: 1 1 200px;
 /* Adjust the flex basis to control when items wrap */
 display: flex;
 flex-direction: column;
 gap: 20px;
}

.about {
 /* border: 3px pink solid; */
 display: flex;
 flex-direction: column;
 flex: 3;
 gap: 20px;
}

.customMenu {
 list-style: none;
 padding: 0;
 margin: 0;
}

.customMenu li::marker {
 color: #AD9779;
}

.customMenu li::before {
 content: '';
 display: inline-block;
 width: 4px;
 height: 4px;
 background-color: #AD9779;
 margin-right: 8px;
 margin-bottom: 4px;
}

.footer {
 background-color: #10172C;
 color: white;
 display: flex;
 gap: 50px;
 padding: 30px 100px;
 flex-wrap: wrap;
 /* Allow items to wrap */
}

.footer p {
 line-height: 25px;
}

.get-in-touch {
 /* border: 3px pink solid; */
 flex: 2;
}

.images-cell {
 width: 100%;
 height: auto;
}

.images-logo {
 width: 90%;
 height: auto;
}

.images-quicklinks {
 width: 90%;
 height: auto;
}

.input {
 height: 70px;
 text-align: center;
 width: 100%;
}

.menuButton {
 background: none;
 /* Removes the background */
 color: inherit;
 /* Inherits the text color from parent */
 border: none;
 /* Removes the border */
 padding: 0;
 /* Removes padding */
 font: inherit;
 /* Inherits the font from parent */
 cursor: pointer;
 /* Keeps the pointer cursor to indicate it's clickable */
 outline: inherit;
 /* Inherits the outline style (useful for accessibility) */
}

.newsletter {
 /* border: 3px pink solid; */
 display: flex;
 flex: 2;
 flex-direction: column;
 gap: 20px;
}

.quicklinks {
 /* border: 3px pink solid; */
 flex: 2;
}

/* Media query for smaller screens */
@media (max-width: 768px) {

 /* Adjust the max-width as needed */
 .footer {
  flex-direction: column;
  align-items: center;
  padding: 20px;
 }

 .about,
 .quicklinks,
 .get-in-touch,
 .newsletter {
  width: 100%;
  /* Make each section full width on small screens */
 }

 /* Adjustments to other elements if needed... */
}